<template>
  <div class="home">
    <div class="banner" v-show="isVShow">
      <h2>Update Your 2024 Medicare Quote</h2>
      <div class="cart_swapper">
        <h3 class="title">See Available Plans in {{ MyAdd }}</h3>
        <div class="content">
          <h3>What is Your Date of Birth?</h3>
          <div>
            <el-select @change="changeMonth" class="input_txt" name="month" v-model="form.month" placeholder="Month"
              :class="{ errorinput: istruemonth }">
              <el-option v-for="item in monthOptions" :key="item.value" :value="item.value"
                :name="item.name"></el-option>
            </el-select>
            <el-select class="input_txt" name="day" v-model="form.day" placeholder="Day"
              :class="{ errorinput: istrueday }">
              <el-option v-for="item in dayOptions" :key="item.value" :value="item.value" :name="item.name"></el-option>
            </el-select>
            <el-select class="input_txt" ref="year" name="year" v-model="form.year" placeholder="Year"
              :class="{ errorinput: istrueday }">
              <el-option v-for="item in yearOptions" :key="item.value" :value="item.value"
                :name="item.name"></el-option>
            </el-select>
            <!-- <el-input class="input_txt" name="year" v-model="form.year" maxlength="4" placeholder="Year"
              oninput="value = value.replace(/[^\d]/g,'')" :class="{ errorinput: istrueyear }"></el-input> -->
            <p v-if="istrueyear || istrueday || istruemonth" class="error_txt">
              Please enter a valid date
            </p>
          </div>
          <div style="text-align: center; margin-top: 10px">
            <el-button class="next_btn" style="
                background: rgba(255, 150, 150, 1);
                color: rgba(255, 255, 255, 1);
              " @click="next">Continue</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-progress :percentage="percentage" :show-text="false" define-back-color="rgba(222, 222, 222, 1)"
      color="rgba(250, 231, 152, 1)"></el-progress>
    <el-main>
      <section class="step">
        <div class="lf img_swapper">
          <img alt="illustration" src="@/assets/imgs/illustration1.png" />
        </div>
        <div class="rg mb">
          <h4>Compare in Minutes</h4>
          <p>Tap a button above to begin.</p>
          <p>
            Why spend hours trying to figure out which medicare providers offer
            the most affordable rates and best policy for your needs? filling
            out a quick and simple form with some basic information about
            yourself.
          </p>
        </div>
      </section>
      <section class="step bgcolor">
        <div class="lf is_show mb">
          <h4>Get Competitive Rates</h4>
          <p>Compare quotes and SAVE with our easy-to-use tool.</p>
          <p>
            The medicare providers in the network are competing for your
            business,Our Smart matching engine will sift the medicare quotes and
            only present you with relevant and valid options.
          </p>
        </div>
        <div class="rg img_swapper is_show">
          <img alt="illustration" src="@/assets/imgs/illustration2.png" />
        </div>
        <div class="lf img_swapper is_phone_show">
          <img alt="illustration" src="@/assets/imgs/illustration2.png" />
        </div>
        <div class="rg is_phone_show pb">
          <h4>Get Competitive Rates</h4>
          <p>Compare quotes and SAVE with our easy-to-use tool.</p>
          <p>
            The medicare providers in the network are competing for your
            business,Our Smart matching engine will sift the medicare quotes and
            only present you with relevant and valid options.
          </p>
        </div>
      </section>
      <section class="step">
        <div class="lf img_swapper">
          <img alt="illustration" src="@/assets/imgs/illustration3.png" />
        </div>
        <div class="rg mb">
          <h4>A Lot Of Savings</h4>
          <p>
            Let us make it simple for you to save!You have the freedom to choose
            the best policy ption that’s right for you.
          </p>
          <p>100% FREE Initial Consultation.</p>
        </div>
      </section>
      <div class="recommend">
        <p class="r_content">
          We shop highly rated insurance companies to save you time & money.
        </p>
        <div class="zanzhu">
          <img alt="wellcare" src="@/assets/imgs/wellcare.png" />
          <img alt="Aetna" src="@/assets/imgs/Aetna.png" />
          <img alt="blue" src="@/assets/imgs/blue.webp" />
          <img alt="cigama" src="@/assets/imgs/cigama.png" />
          <img alt="CVS_Health_logo" src="@/assets/imgs/CVS_Health_logo.png" />
          <img alt="h" src="@/assets/imgs/h.webp" />
          <img alt="kalser" src="@/assets/imgs/kalser.webp" />
          <p class="clearflex"></p>
        </div>
      </div>
      <div class="trademark">
        <img alt="trademark_1" src="@/assets/imgs/trademark_1.png" />
        <img alt="trademark_2" src="@/assets/imgs/trademark_2.png" />
        <img alt="trademark_3" src="@/assets/imgs/trademark_3.png" />
      </div>
    </el-main>
  </div>
</template>

<script>
import { getIpAddress, GetIPadd } from "@/api/index";
import { Select, Option } from "element-ui";
Vue.use(Select);
Vue.use(Option);
export default {
  name: "Home",
  components: {},
  data() {
    return {
      MyAdd: "",
      percentage: 10,
      form: {},
      istruemonth: false,
      istrueday: false,
      istrueyear: false,
      isVShow: false,
      yearOptions: [],
      monthOptions: [
        { value: "1", name: "01" },
        { value: "2", name: "02" },
        { value: "3", name: "03" },
        { value: "4", name: "04" },
        { value: "5", name: "05" },
        { value: "6", name: "06" },
        { value: "7", name: "07" },
        { value: "8", name: "08" },
        { value: "9", name: "09" },
        { value: "10", name: "10" },
        { value: "11", name: "11" },
        { value: "12", name: "12" },
      ],
      dayOptions: [],
    };
  },
  created() {
    this.$nextTick(() => {
      this.isVShow = true;
      let cardfrom = localStorage.getItem("cardfrom");
      if (cardfrom) {
        let temp = { ...JSON.parse(cardfrom), ...this.form };
        this.form = temp;
      }
    });
  },
  mounted() {
    this.isfrom();
    this.getIP();
    this.getYear();
    localStorage.setItem(
      "afclickid",
      document.location.href?.split("?")[1]?.split("=")[1]
    );
  },
  methods: {
    getYear() {
      const currentYear = new Date().getFullYear();
      const startYear = 1935;
      const options = [];

      for (let year = startYear; year <= 2005; year++) {
        options.push({ value: year, name: year });
      }
      this.yearOptions = options.reverse();

      console.log(this.$refs.year.value);
    },
    changeMonth(value) {
      // console.log(value);
      this.dayOptions = [
        { value: "1", name: "01" },
        { value: "2", name: "02" },
        { value: "3", name: "03" },
        { value: "4", name: "04" },
        { value: "5", name: "05" },
        { value: "6", name: "06" },
        { value: "7", name: "07" },
        { value: "8", name: "08" },
        { value: "9", name: "09" },
        { value: "10", name: "10" },
        { value: "11", name: "11" },
        { value: "12", name: "12" },
        { value: "13", name: "13" },
        { value: "14", name: "14" },
        { value: "15", name: "15" },
        { value: "16", name: "16" },
        { value: "17", name: "17" },
        { value: "18", name: "18" },
        { value: "19", name: "19" },
        { value: "20", name: "20" },
        { value: "21", name: "21" },
        { value: "22", name: "22" },
        { value: "23", name: "23" },
        { value: "24", name: "24" },
        { value: "25", name: "25" },
        { value: "26", name: "26" },
        { value: "27", name: "27" },
        { value: "28", name: "28" },
      ];
      if (["1", "3", "5", "7", "8", "10", "12"].indexOf(value) >= 0) {
        this.dayOptions = this.dayOptions.concat([
          { value: "29", name: "29" },
          { value: "30", name: "30" },
          { value: "31", name: "31" },
        ]);
        return;
      }
      if (["4", "6", "9", "11"].indexOf(value) >= 0) {
        this.dayOptions = this.dayOptions.concat([
          { value: "29", name: "29" },
          { value: "30", name: "30" },
        ]);
        return;
      }
      if (value == 2) {
        let year = this.form.year;
        if (year) {
          if (
            (parseInt(year) % 4 == 0 && parseInt(year) % 100 != 0) ||
            parseInt(year) % 400 == 0
          ) {
            this.dayOptions = this.dayOptions.concat([
              { value: "29", name: "29" },
            ]);
            return;
          }
        }
      }
    },
    next() {
      this.istruemonth = false;
      this.istrueday = false;
      this.istrueyear = false;
      if (!this.form.month || this.form.month > 12 || this.form.month < 1) {
        this.istruemonth = true;
        return;
      }
      if (!this.form.day) {
        this.istrueday = true;
        return;
      } else {
        if (
          ["1", "3", "5", "7", "8", "10", "12"].indexOf(this.form.month) >= 0
        ) {
          if (this.form.day > 31 || this.form.day < 1) {
            this.istrueday = true;
            return;
          }
        }
        if (["4", "6", "9", "11"].indexOf(this.form.month) >= 0) {
          if (this.form.day > 30 || this.form.day < 1) {
            this.istrueday = true;
            return;
          }
        }
        if (this.form.month == 2) {
          let year = this.form.year;
          if (year) {
            if (
              (parseInt(year) % 4 == 0 && parseInt(year) % 100 != 0) ||
              parseInt(year) % 400 == 0
            ) {
              if (this.form.day > 29 || this.form.day < 1) {
                this.istrueday = true;
                return;
              }
            } else {
              if (this.form.day > 28 || this.form.day < 1) {
                this.istrueday = true;
                return;
              }
            }
          }
        }
      }
      if (
        !this.form.year ||
        this.form.year > new Date().getFullYear() ||
        this.form.year < 1790
      ) {
        this.istrueyear = true;
        return;
      }
      this.birthday =
        this.form.year + "/" + this.form.month + "/" + this.form.day;
      // console.log(this.form);
      localStorage.setItem("cardfrom", JSON.stringify(this.form));
      console.log(localStorage.getItem("cardfrom"));
      localStorage.setItem("step", 0);

      this.$router.push({ path: "/ProcessForm" }).catch(() => { });
    },
    isfrom() {
      if (localStorage.getItem("percentage")) {
        this.percentage = localStorage.getItem("percentage") - 0;
      }
    },
    async getIP() {
      if (!localStorage.getItem("userip")) {
        const res = await getIpAddress();
        // console.log(res);
        this.getipadd(res);
      } else {
        this.MyAdd = JSON.parse(localStorage.getItem("userip")).city;
      }
    },
    async getipadd(ip) {
      console.log(ip);
      const res = await GetIPadd(ip)
        .then(() => {
          this.MyAdd = res.city;
          localStorage.setItem("userip", JSON.stringify(res));
        })
        .catch(() => {
          localStorage.setItem("userip", JSON.stringify({ ip }));
        });
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (newVal) {
        this.changeMonth(newVal.month);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/layout.scss";

.home {
  margin-top: 70px;

  .banner {
    background-image: url(../assets/imgs/banner2.webp);
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% - 20%);
    height: 500px;
    padding: 50px 10%;

    h2 {
      line-height: 1.5;
      font-size: 35px;
      color: rgba(255, 255, 255, 1);
      padding-left: 20px;
    }

    .cart_swapper {
      background-image: url(../assets/imgs/cartbg.png);
      background-repeat: no-repeat;
      background-size: cover;
      height: 400px;
      width: 650px;
      border-radius: 10px;
      margin-top: 20px;

      h3.title {
        font-size: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        line-height: 3;
      }

      .content {
        width: 100%;
        margin-top: 45px;

        h3 {
          text-align: center;
          line-height: 2;
          font-size: 28px;
        }

        text-align: center;

        .input_txt {
          width: 15%;
          padding: 20px 4% 10px 4%;

          .el-input__inner {
            text-align: center;
            border: 1px solid rgba(28, 56, 136, 1);
            height: 45px;
          }
        }

        .errorinput {
          .el-input__inner {
            border: 1px solid #eb737a;
          }
        }
      }

      .next_btn {
        background: rgba(255, 150, 150, 1);
        color: rgba(255, 255, 255, 1);
        margin-top: 20px;
        width: 128px;
        height: 46px;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .error_txt {
      color: #eb737a;
      margin: 0px 0px 0px 10px;
      padding: 0px;
      height: 25px;
      line-height: 25px;
      text-align: left;
      margin-left: 19%;
    }

    .el-progress-bar__outer {
      border-radius: 0px !important;
    }
  }

  .el-main {
    padding: 0px;
    overflow: hidden;

    .step {
      display: flex;
      min-height: 250px;
      box-sizing: border-box;
      padding: 0px 25%;
      justify-content: center;
      align-items: center;

      .lf,
      .rg {
        width: 80%;
        margin-bottom: 10px;

        img {
          vertical-align: middle;
          height: 200px;
          margin: 30px 0px;
        }

        padding: 0px 5%;

        h4 {
          font-size: 25px;
          line-height: 3;
          color: rgba(255, 150, 150, 1);
          text-align: center;
        }

        p {
          font-size: 18px;
          color: rgba(28, 56, 136, 1);
          text-align: left;
        }
      }

      .is_show {
        display: block;
      }

      .is_phone_show {
        display: none;
      }

      .img_swapper {
        width: 20%;
        text-align: center;
      }
    }

    .bgcolor {
      background: rgba(242, 242, 242, 0.7);
    }

    .recommend {
      text-align: center;

      .r_content {
        font-size: 16px;
        color: rgba(28, 56, 136, 1);
        font-weight: 700;
        padding: 10px 0px;
      }

      .zanzhu {
        width: 100%;
        background-color: #f5f5f5;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        text-align: center;

        img {
          vertical-align: middle;
          margin: 20px 30px;
          width: 110px;
        }
      }

      .clearflex {
        width: 100%;
        clear: both;
      }
    }

    .trademark {
      text-align: center;
      margin: 30px 0px;

      img {
        padding: 50px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .home {
    .banner {
      background-size: 100% 100%;
      height: 400px;
      text-align: center;

      h2 {
        padding: 10px;
        font-size: 35px;
      }

      .cart_swapper {
        width: 100%;
        margin-top: 10px;
        height: 350px;

        .content {
          .input_txt {
            width: 20%;
            padding: 20px 4% 10px 4%;
          }
        }
      }

      .error_txt {
        margin-left: 12%;
        font-size: 14px;
      }
    }

    .el-main {
      .step {
        padding: 0px 5%;

        .lf,
        .rg {
          p {
            font-size: 20px;
          }
        }

        .mb {
          margin-bottom: 40px;
        }

        .pb {
          padding-bottom: 40px;
        }

        .is_show {
          display: block;
        }

        .is_phone_show {
          display: none;
        }
      }

      .recommend {
        .r_content {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    .banner {
      width: 100%;
      padding: 0;
      background-size: 100% 100%;
      height: 450px;

      h2 {
        padding: 10px;
        font-size: 25px;
      }

      .cart_swapper {
        width: 86%;
        margin: 0px auto;
      }

      .error_txt {
        margin-left: 12%;
      }
    }

    .el-main {
      .step {
        padding: 0px;
        display: block;

        .lf,
        .rg {
          width: calc(100% - 10%);
          text-align: center;
          box-sizing: content-box;

          img {
            margin-bottom: 10px;
            height: 120px;
          }

          h4 {
            font-size: 20px;
          }

          p {
            font-size: 16px;
          }
        }

        .mb {
          margin-bottom: 40px;
        }

        .pb {
          padding-bottom: 40px;
        }

        .is_show {
          display: none;
        }

        .is_phone_show {
          display: block;
        }

        .img_swapper {
          text-align: center;
        }
      }

      .trademark {
        img {
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .home {
    .banner {
      h2 {
        font-size: 20px;
      }

      .cart_swapper {
        width: 96%;
        margin: 0px auto;

        .content {
          h3 {
            font-size: 24px;
          }
        }
      }
    }
  }
}

.el-select-dropdown.el-popper {
  min-width: 9% !important;
  height: 40%;

  .el-scrollbar {
    height: 100% !important;
  }

  .el-scrollbar__wrap {
    min-height: 105%;
  }
}
</style>
